.ExpandedRow {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  background-color: rgb(216, 216, 216);
  font-size: 17px;
  font-family: var(--main-font);
  width: 100%;
}

.ExpandedRow-main {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
}

.ExpandedRow--title {
  font-size: 20px;
  margin-bottom: 10px;
}

.ExpandedRow--left {
  margin: 10px 25px;
  width: 50%;
}

.ExpandedRow--right {
  display: flex;
  margin: 10px 25px;
  width: 50%;
  flex-direction: column;
  justify-content: start;
}

.ExpandedRow--details,
.ExpandedRow-form-box {
  display: flex;
}

.ExpandedRow--details-labels {
  margin: 0px 20px;
}
.ExpandedRow--details-data {
  font-style: italic;
}

.ExpandedRow--sign-form {
  display: flex;
  flex-direction: column;
}
.ExpandedRow--sign-form-main {
  display: flex;
}

.ExpandedRow-form-box > input {
  border-radius: 20px;
  font-size: 17px;
  padding: 0px 10px;
}

.ExpandedRow--login-to-tournament-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  transition: box-shadow 0.3s;
  font-size: 20px;
  font-family: var(--main-font);
  font-style: italic;
  color: rgb(245, 245, 245);
  border-radius: 20px;
  height: 30px;
  width: 35%;
  margin-left: 15px;
}
.ExpandedRow--logout-from-tournament-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  transition: box-shadow 0.3s;
  font-size: 20px;
  font-family: var(--main-font);
  font-style: italic;
  color: rgb(245, 245, 245);
  border-radius: 20px;
  height: 30px;
  width: 35%;
  margin-left: 15px;
}

.ExpandedRow--login-to-tournament-btn:hover,
.ExpandedRow--logout-from-tournament-btn:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.7);
}

.ExpandedRow--editer-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 15px;
}

.ExpandedRow--editer-buttons > button {
  color: rgb(245, 245, 245);
  width: 25%;
  font-size: 20px;
  border-radius: 20px;
  font-family: var(--main-font);
  height: 30px;
}

.ExpandedRow--edit-button {
  background-color: rgb(33, 33, 33);
  margin-right: 50px;
}

.ExpandedRow--delete-button {
  background-color: red;
  margin-left: 50px;
}

.ExpandedRow--login-link {
  font-style: italic;
  color: rgb(241, 90, 90);
}

.ExpandedRow--login-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.ExpandedRow--mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid black;
  background-color: rgb(190, 190, 190);
  /* color: rgb(245, 245, 245); */
  color: rgb(33, 33, 33);
  font-size: 28px;
  font-family: var(--main-font);
  width: 100%;
}

.ExpandedRow-main--mobile {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.ExpandedRow--title-mobile {
  font-size: 30px;
  margin-bottom: 10px;
}

.ExpandedRow--left-mobile {
  margin: 10px 25px;
  width: 80%;
}

.ExpandedRow--right-mobile {
  display: flex;
  margin: 10px 25px;
  flex-direction: column;
  justify-content: start;
  width: 80%;
}

.ExpandedRow--details,
.ExpandedRow-form-box {
  display: flex;
}

.ExpandedRow--details-labels {
  margin: 0px 20px;
}
.ExpandedRow--details-data {
  font-style: italic;
}

.ExpandedRow--sign-form {
  display: flex;
  flex-direction: column;
}
.ExpandedRow--sign-form-main {
  display: flex;
}
.ExpandedRow--sign-form-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ExpandedRow-form-box-mobile > input {
  border-radius: 20px;
  font-size: 27px;
  padding: 0px 10px;
}

.ExpandedRow--login-to-tournament-btn-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  transition: box-shadow 0.3s;
  font-size: 28px;
  font-family: var(--main-font);
  font-style: italic;
  color: rgb(245, 245, 245);
  border-radius: 20px;
  height: 40px;
  width: 50%;
  margin-left: 15px;
}
.ExpandedRow--logout-from-tournament-btn-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  transition: box-shadow 0.3s;
  font-size: 28px;
  font-family: var(--main-font);
  font-style: italic;
  color: rgb(245, 245, 245);
  border-radius: 20px;
  height: 40px;
  width: 50%;
  margin-left: 15px;
}

.ExpandedRow--editer-buttons-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 15px;
}

.ExpandedRow--editer-buttons-mobile > button {
  color: rgb(245, 245, 245);
  width: 35%;
  font-size: 25px;
  border-radius: 20px;
  font-family: var(--main-font);
  height: 50px;
}
