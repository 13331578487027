/* Main component style */
.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url("../../public/login-orange.webp");
  /* background: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  ); */
  background-size: cover; /* Cover the entire container */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-position: center; /*Center the image */
}

.Login--registration_failed {
  background-color: rgb(255, 0, 0);
  color: rgb(245, 245, 245);
}

/* PLAYER/ORGANIZER switch buttons */
.Login--organizer-player-switch {
  width: 600px;
  margin-top: 50px;
}

.Login--player-btn,
.Login--organizer-btn {
  font-family: var(--main-font);
  font-size: 22px;
  border-top-right-radius: 50px;
  border-top-left-radius: 5px;
  color: rgb(245, 245, 245);
  background-color: rgb(131, 110, 110);
  width: 50%;
  border: 0;
  box-shadow: 0 0 10px rgb(37, 31, 31);
}

.chosen {
  background-color: rgb(37, 31, 31);
}

/* X Button style */
.Login--x-btn {
  position: absolute;
  top: 2px;
  right: 10px;
  font-family: var(--x-font);
  font-size: 30px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
  text-align: center;
}

.Login--x-btn:hover {
  text-shadow: 0 0 25px rgba(247, 28, 61, 1);
  cursor: pointer;
}

/* Main login box style*/
.login-box {
  position: relative;
  display: flex;
  margin-bottom: 50px;
  padding: 50px; /* Add some padding for spacing */
  background-color: rgb(37, 31, 31); /* Background color for the login box */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
  box-shadow: 0 0 25px rgb(37, 31, 31);
}

/* Title */
.login-form-title {
  width: 100%;
  display: block;
  font-family: var(--main-font);
  font-size: 30px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Social buttons */
.socials-buttons {
  display: flex;
  font-family: "Montserrat", sans-serif;
}

.facebook-button,
.google-button {
  font-size: 18px;
  line-height: 1.2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  width: calc((100% - 20px) / 2);
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
}
.google-button::before,
.facebook-button::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(
    45deg,
    rgba(255, 164, 46, 1),
    rgba(247, 28, 61, 1)
  );
  background: -o-linear-gradient(
    45deg,
    rgba(255, 164, 46, 1),
    rgba(247, 28, 61, 1)
  );
  background: -moz-linear-gradient(
    45deg,
    rgba(255, 164, 46, 1),
    rgba(247, 28, 61, 1)
  );
  background: linear-gradient(
    45deg,
    rgba(255, 164, 46, 1),
    rgba(247, 28, 61, 1)
  );
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.facebook-button {
  color: #fff;
  background-color: #3b5998;
}
.facebook-button i {
  font-size: 30px;
  margin-right: 17px;
  padding-bottom: 3px;
}
.google-button {
  color: #555;
  background-color: #fff;
}
.google-logo,
.facebook-logo {
  width: 30px;
  margin-right: 15px;
  padding-bottom: 3px;
}
.facebook-button:hover:before,
.google-button:hover:before {
  opacity: 1;
  cursor: default;
}
.facebook-button:hover,
.google-button:hover {
  color: #fff;
  cursor: default;
}

/* Form box include email password and submit button */
.Login--main-form {
  width: 500px;
  margin: 40px 0px;
}

.wrap-input > input {
  font-family: var(--input-font);
  font-size: 18px;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #e6e6e6;
  height: 40px;
  border-radius: 10px;
}

.Login--login-button {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  background-color: #333;
  border-radius: 10px;
  font-family: var(--main-font);
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
  width: 100%;
  border: 0;
}

.Login--login-button > p {
  transition: all 0.4s;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
}

.Login--login-button:hover {
  box-shadow: 0 0 11px rgba(255, 255, 255, 0.7);
}

/* Bottom part with register */
.Login--register {
  display: flex;
  position: relative;
  justify-content: center;
  bottom: 0;
}

.Login--register > p {
  font-family: var(--main-font);
  font-size: 17px;
  color: #999;
  line-height: 1.5;
  margin-right: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Popup error and success messages */
.Login--registration_succesfull,
.Login--registration_failed {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: rgb(255, 0, 0);
  font-family: var(--main-font);
  width: 600px;
  height: 25px;
  border-radius: 15px;
}

.Login--registration_succesfull {
  background-color: rgb(0, 255, 0);
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

/* PLAYER/ORGANIZER switch buttons */
.Login--organizer-player-switch-mobile {
  width: 95%;
}

.Login--organizer-player-switch-mobile > button {
  height: 80px;
  font-size: 45px;
}

/* X Button style */
.Login--x-btn-mobile {
  position: absolute;
  top: 2px;
  right: 20px;
  font-family: var(--x-font);
  font-size: 80px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
  text-align: center;
}

/* Main login box style*/
.login-box-mobile {
  position: relative;
  display: flex;
  background-color: rgb(37, 31, 31); /* Background color for the login box */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
  box-shadow: 0 0 25px rgb(37, 31, 31);
  width: 95%;
}

/* Box inside main box without X button*/
.login-form-mobile {
  width: 85%;
  margin: 100px 0px;
}

/* Email and password whole boxes with label and input */
.Login--main-form-mobile > div {
  margin-top: 40px;
}

/* Email and password labels */
.Login--main-form-mobile > div > label {
  font-size: 40px;
}

/* Email and password input fields */
.Login--main-form-mobile > div > input {
  font-size: 40px;
  height: 75px;
}

/* Login sumbmit button */
.Login--main-form-mobile > button {
  font-size: 35px;
  height: 90px;
  margin-top: 25px;
}

/* Title */
.login-form-title-mobile {
  width: 100%;
  display: block;
  font-family: var(--main-font);
  font-size: 65px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Social buttons */
.socials-buttons-mobile {
  display: flex;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 50px;
}

.socials-buttons-mobile > div {
  font-size: 30px;
  height: 80px;
}

.socials-buttons-mobile > div > img {
  height: 45px;
  width: auto;
}

/* bottom register label field */
.Login--register-mobile {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
}

.Login--register-mobile > p {
  font-family: var(--main-font);
  font-size: 35px;
  color: #999;
  line-height: 1.5;
  margin-right: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}
