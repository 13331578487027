.AddTournament {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AddTournament--form {
  width: 35%;
}

.AddTournament--form-element {
  margin: 20px 0px;
}

.AddTournament--form-element-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.AddTournament--form-element-checkbox > input {
  height: 40px;
  width: 40px;
}

.AddTournament--submit-button-box {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.AddTournament--submit-button {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  background-color: rgb(37, 31, 31);
  border-radius: 10px;
  font-family: var(--main-font);
  font-size: 20px;
  color: rgb(216, 216, 216);
  margin: 10px;
  width: 50%;
}

.AddTournament--submit-button:hover {
  box-shadow: 0 0 11px rgb(37, 31, 31);
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.AddTournament--form-mobile {
  width: 90%;
}

.AddTournament--form-mobile > div {
  height: 150px;
}
.AddTournament--form-mobile > div > label {
  font-size: 35px;
}
.AddTournament--form-mobile > div > input {
  font-size: 35px;
  height: 60px;
}

.AddTournament--form-mobile > div > div > div {
  font-size: 30px;
}

.AddTournament--submit-button-box-mobile {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.AddTournament--submit-button-box-mobile > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 90px;
  background-color: rgb(37, 31, 31);
  border-radius: 10px;
  font-family: var(--main-font);
  font-size: 45px;
  color: rgb(216, 216, 216);
  margin: 10px;
  width: 80%;
}
