.Resources--main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  font-family: var(--main-font);
  margin-bottom: 500px;
}

.Resources--content {
  display: flex;
  width: 75%;
  flex-direction: column;
  justify-content: left;
}

.Resources--content > h1 {
  font-size: 25px;
}

.Resources--resources {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.Resources--resource {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%; /* Each item should take up 1/3 of the container width */
  height: 150px;
  box-sizing: border-box; /* Include padding and border in the width calculation */
  padding: 10px; /* Optional: Add padding to the items */
}

.Resources--resource > img {
  width: 120px;
  height: auto;
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.Resources--content-mobile {
  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: left;
  margin-top: 100px;
}

.Resources--resource-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* Each item should take up 1/3 of the container width */
  height: 150px;
  box-sizing: border-box; /* Include padding and border in the width calculation */
  padding: 10px; /* Optional: Add padding to the items */
  margin: 50px 0px;
}

.Resources--resource-mobile > img {
  width: 200px;
  height: auto;
}
