.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(37, 31, 31);
  font-family: var(--main-font);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 1);
}

.navbar--left {
  flex: 1;
}

.navbar--center {
  display: flex;
  flex: 1;
  justify-content: center;
  color: rgb(245, 245, 245);
}

.navbar--right {
  display: flex;
  flex: 1;
  justify-content: right;
  margin-right: 25px;
}

.navbar--logo,
.navbar--logo-mobile {
  transition: box-shadow 0.3s;
  margin: 10px 20px;
  height: 40px;
  padding: 5px;
  border-radius: 50px;
}

.navbar--logo-mobile {
  height: 70px;
  margin-left: 30px;
}

.navbar--logo:hover {
  cursor: pointer;
  box-shadow: 0 0 20px rgba(247, 28, 61, 1);
}

.login > input {
  width: 200px;
}

.navbar--login-btn,
.navbar--login-btn-mobile {
  transition: box-shadow 0.3s;
  font-family: var(--main-font);
  font-size: 22px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
  padding: 3px 10px 3px 10px;
  border-radius: 20px;
}

.navbar--login-btn-mobile {
  font-size: 35px;
}

.navbar--login-btn:hover {
  box-shadow: 0 0 20px rgba(247, 28, 61, 1);
  cursor: pointer;
}
