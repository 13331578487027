:root {
  /* --main-font: "Bebas Neue", sans-serif; */
  --main-font: "Autour One", sans-serif;
  --input-font: "Montserrat", sans-serif;
  --x-font: "Autour One", sans-serif;
}

/* Otehr fonts */
/* "Bebas Neue", sans-serif; */
/* "PT Serif", serif; */
/* "Montserrat", sans-serif; */
/* "Autour One", sans-serif */
