.Footer--main,
.Footer--main-mobile {
  display: flex;
  background-color: rgb(37, 31, 31);
  color: rgb(216, 216, 216);
  font-family: var(--main-font);
  font-size: 15px;
}

.Footer--main-mobile {
  font-size: 30px;
}

.Footer--main > div,
.Footer--main-mobile > div {
  display: flex;
  flex-direction: column;
  border: 3px solid rgb(216, 216, 216);
  border-radius: 20px;
  width: 33.33%;
  margin: 20px 60px;
  padding: 15px 20px;
}

.Footer--main-mobile > div {
  width: 50%;
  margin: 40px;
}

.Footer--main > div > h3 {
  margin: 5px 5px 15px 5px;
}
.Footer--main-mobile > div > h3 {
  margin: 5px 5px 15px 5px;
  font-size: 35px;
}

.Footer--main > div > p {
  margin: 5px;
}

.Footer--main-mobile > div > p {
  margin: 15px;
}

.Footer--logo {
  width: 60%;
}
