/* Main component */
.Calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Whole calendar coponent */
.Calendar--calendar-box {
  width: 75%;
  height: 700px;
  background-color: rgb(245, 245, 245);
  font-family: var(--main-font);
  font-size: 17px;
}

.Calendar--url-button-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Calendar--url-button-box > button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: 240px;
  height: 35px;
  font-size: 20px;
  border-radius: 15px;
  background-color: black;
  color: rgb(245, 245, 245);
  font-family: var(--main-font);
}

.Calendar--url-button-box > button:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.7);
}

.rbc-event-content {
  font-family: var(--main-font);
  font-size: 17px;
}

.rbc-toolbar {
  background-color: rgb(37, 31, 31);
  font-family: var(--main-font);
  color: rgb(245, 245, 245);
}

.rbc-toolbar > span {
  font-size: 20px;
  font-family: var(--main-font);
}

.rbc-toolbar > span > button {
  font-family: var(--main-font);
  font-size: 20px;
  color: rgb(245, 245, 245);
}

.Calendar--calendar-box .rbc-today {
  background: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
}

/* -------------- Detail window styles --------------*/
.Calendar--detail-window {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font);
}

.Calendar--detail-window-textbox {
  display: flex;
  margin: 10px 15px;
}

.Calendar--detail-window-textbox > p {
  margin-top: 3px;
  margin-bottom: 3px;
  color: rgb(37, 31, 31);
}

.label-text {
  font-size: 20px;
  margin-right: 15px;
}

.content-text {
  /* font-style: italic; */
  font-size: 20px;
}

.Calendar--detail-window-last-update {
  display: flex;
  justify-content: center;
  font-style: italic;
  font-size: 20px;
  color: rgb(80, 80, 80);
}

.Calendar--detail-window-body {
  display: flex;
  font-size: 20px;
}

.Calendar--detail-window-body-data {
  font-style: italic;
  margin-left: 20px;
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.Calendar--calendar-box-mobile {
  width: 98%;
  height: 800px;
  background-color: rgb(245, 245, 245);
  font-family: var(--main-font);
  font-size: 20px;
}

.Calendar--url-button-box-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Calendar--url-button-box-mobile > button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: 240px;
  height: 35px;
  font-size: 22px;
  border-radius: 15px;
  background-color: black;
  color: rgb(245, 245, 245);
  font-family: var(--main-font);
}

.Calendar--calendar-box-mobile .rbc-today {
  background: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
}

/* -------------- Detail window styles --------------*/
.Calendar--detail-window-mobile > div > h1 {
  font-size: 70px;
}

.Calendar--detail-window-textbox-mobile {
  display: flex;
  margin: 10px 15px;
}

.Calendar--detail-window-textbox-mobile > p {
  margin-top: 3px;
  margin-bottom: 3px;
  color: rgb(37, 31, 31);
}

.Calendar--detail-window-body-mobile {
  display: flex;
  font-size: 40px;
}

.Calendar--detail-window-body-mobile > div {
  margin-right: 40px;
}

.Calendar--detail-window-body-mobile {
  display: flex;
  font-size: 50px;
}

.Calendar--detail-window-last-update-mobile {
  display: flex;
  justify-content: center;
  font-style: italic;
  font-size: 40px;
  color: rgb(80, 80, 80);
}
