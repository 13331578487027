/* main component style */
.filters {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.filters-form {
  display: flex;
  justify-content: center;
}

.filters-form .css-13cymwt-control {
  background-color: rgb(245, 245, 245);
}

.filters-form .css-t3ipsp-control {
  background-color: rgb(245, 245, 245);
}

.filters-form > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: var(--main-font);
  margin-left: 10px;
  margin-right: 10px;
}

/* Filter button */
.Filters--save-button {
  font-size: 18px;
  display: flex;
  transition: box-shadow 0.3s;
  font-family: var(--main-font);
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  background-color: rgb(37, 31, 31);
  color: white;
  border-radius: 50px;
  border-width: 0;
  margin-top: auto;
}

/* Show and Hide filter button */
.Filters--show-filters-btn {
  font-size: 20px;
  display: flex;
  transition: box-shadow 0.3s;
  font-family: var(--main-font);
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  /* background-color: rgb(37, 31, 31); */
  background-color: rgb(216, 216, 216);
  /* color: white; */
  border-radius: 50px;
  border-width: 0;
  margin-top: auto;
}

.Filters--hide-filters-btn {
  font-size: 20px;
  display: flex;
  transition: box-shadow 0.3s;
  font-family: var(--main-font);
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  background-color: rgb(37, 31, 31);
  color: white;
  border-radius: 50px;
  border-width: 0;
  margin-top: auto;
  font-size: 28px;
  font-family: var(--x-font);
  background-color: rgb(216, 216, 216);
  font-weight: bold;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
  padding-bottom: 0;
}

/* Button hovers */
.Filters--save-button:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.7);
}
.Filters--show-filters-btn:hover {
  cursor: pointer;
  text-shadow: 0 0 11px rgba(33, 33, 33, 0.7);
}

.Filters--hide-filters-btn:hover {
  cursor: pointer;
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.7);
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.filters-mobile {
  margin: 30px 0px;
}

.Filters--save-button-mobile {
  display: flex;
  transition: box-shadow 0.3s;
  font-family: var(--main-font);
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  background-color: rgb(37, 31, 31);
  color: white;
  border-radius: 50px;
  border-width: 0;
  margin-top: auto;
  margin: 20px;
  font-size: 30px;
}

.filters-form-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.filters-form-mobile .css-13cymwt-control {
  background-color: rgb(245, 245, 245);
}

.filters-form-mobile .css-t3ipsp-control {
  background-color: rgb(245, 245, 245);
}

.filters-form-mobile > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: var(--main-font);
  margin-left: 10px;
  margin-right: 10px;
}

.filters-form-mobile > div {
  width: 40%;
  font-size: 30px;
}

.filters-form-mobile > div > h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}
