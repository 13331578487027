/* main component style */
.Data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Main table component */
.Data--tournament-table {
  width: 75%;
}

/* Table all components */
.Data--tournament-table .rdt_TableHeadRow,
.Data--tournament-table .rdt_TableRow,
.Data--tournament-table .rdt_Pagination {
  background-color: rgb(216, 216, 216);
  font-size: 17px;
  font-family: var(--main-font);
}

/* Table head row */
.Data--tournament-table .rdt_TableHeadRow {
  background-color: rgb(37, 31, 31);
  color: rgb(245, 245, 245);
  font-size: 20px;
}

/* Table row */
.Data--tournament-table .rdt_TableRow {
  height: 60px;
}

.Data--tournament-table .rdt_TableRow:hover {
  cursor: pointer;
}

/* Table tail row */
.rdt_Pagination > span,
.rdt_Pagination > div,
.rdt_Pagination > div > select {
  font-size: 20px;
  font-family: var(--main-font);
}

/* no data table background */
.custom-no-data-background > div > div > div {
  background-color: rgb(216, 216, 216);
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.Data--tournament-table-mobile {
  width: 98%;
}

/* Table all components */
.Data--tournament-table-mobile .rdt_TableHeadRow,
.Data--tournament-table-mobile .rdt_TableRow,
.Data--tournament-table-mobile .rdt_Pagination {
  background-color: rgb(216, 216, 216);
  font-size: 28px;
  font-family: var(--main-font);
}

/* Table head row */
.Data--tournament-table-mobile .rdt_TableHeadRow {
  background-color: rgb(37, 31, 31);
  color: rgb(245, 245, 245);
  font-size: 28px;
  height: 70px;
}

.Data--tournament-table-mobile .rdt_TableCol_Sortable {
  display: flex;
  justify-content: center;
}

/* Table row */
.Data--tournament-table-mobile .rdt_TableRow {
  height: 90px;
}

/* Table cell */
.Data--tournament-table-mobile .rdt_TableCell {
  display: flex;
}

/* Pagination bar */
.Data--tournament-table-mobile .svYMe {
  margin-right: 20px;
  width: 50px;
}

.Data--tournament-table-mobile .svYMe > select {
  font-size: 25px;
}

.Data--tournament-table-mobile .svYMe > svg {
  transform: scale(2);
}

.Data--tournament-table-mobile .rdt_Pagination {
  display: flex;
}

.Data--tournament-table-mobile .rdt_Pagination > span {
  font-size: 26px;
}
.Data--tournament-table-mobile .rdt_Pagination > div > button {
  width: 100px;
}
.Data--tournament-table-mobile .rdt_Pagination > div > button > svg {
  transform: scale(2.5);
}
