.Profile--main {
  margin: 0px 0px 500px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--main-font);
}

h1 {
  font-size: 30px;
}
.Profile--buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Profile--buttons > a,
.Profile--buttons > button {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: rgb(37, 31, 31);
  border-radius: 10px;
  font-family: var(--main-font);
  font-size: 20px;
  color: rgb(245, 245, 245);
  margin: 10px;
  /* width: 80%; */
}
.Profile--buttons > a:hover,
.Profile--buttons > button:hover {
  box-shadow: 0 0 11px rgb(37, 31, 31);
}

.Profile--request-box {
  font-size: 22px;
  width: 75%;
}
.Profile--request-box > h3,
.Profile--request-box > div > h3 {
  /* background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; */
  font-style: italic;
}

.Profile--request-btn-box {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.Profile--request-btn-box > h3 {
  margin: 0px;
}
.Profile--request-btn-box > button {
  display: flex;
  flex: 1;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  margin: 0px 50px 0px 50px;
  padding: 0px 50px 0px 50px;
  transition: box-shadow 0.3s;
  font-size: 22px;
  font-family: var(--main-font);
  font-style: italic;
}
.Profile--request-btn-box > button:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.7);
}

.Profile--request-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  /* width: 150px; */
  height: 40px;
  font-size: 17px;
  border-radius: 15px;
  background-color: rgb(37, 31, 31);
  color: rgb(245, 245, 245);
  border-radius: 50px;
  border-width: 0;
}

.Profile-request-sent {
  font-size: 45px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
}

.save-button {
  display: flex;
  transition: box-shadow 0.3s;
  font-size: 22px;
  font-family: var(--main-font);
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  background-color: rgb(37, 31, 31);
  color: rgb(245, 245, 245);
  border-radius: 50px;
  border-width: 0;
  margin-top: auto;
}

.Profile--user-data {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.Profile--user-data-body {
  display: flex;
  margin-left: 14px;
  margin-bottom: 0px;
  font-size: 17px;
}

.Profile--user-data-body > div > p {
  margin-top: 0px;
}

.Profile--user-data-body-data {
  margin-left: 15px;
}

.Profile--change-password-box {
  border-top: 2px solid rgb(37, 31, 31);
  margin: 3px 0px 5px 14px;
  padding-top: 15px;
}

.Profile--change-password-box > a {
  transition: box-shadow 0.3s;
  font-family: var(--main-font);
  font-size: 17px;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding-right: 10px;
  border-radius: 20px;
  font-style: italic;
}

.Profile--change-passwor-box > a:hover {
  cursor: pointer;
  text-shadow: 0 0 1px rgb(37, 31, 31);
}

/* Main table component */
.Profile--tournament-table {
  width: 75%;
}

/* Table all components */
.Profile--tournament-table .rdt_TableHeadRow,
.Profile--tournament-table .rdt_TableRow,
.Profile--tournament-table .rdt_Pagination {
  background-color: rgb(216, 216, 216);
  font-size: 17px;
  font-family: var(--main-font);
}

/* Table head row */
.Profile--tournament-table .rdt_TableHeadRow {
  background-color: rgb(37, 31, 31);
  color: rgb(245, 245, 245);
  font-size: 20px;
}

/* Table row */
.Profile--tournament-table .rdt_TableRow {
  height: 60px;
}

.Profile--tournament-table .rdt_TableRow:hover {
  cursor: pointer;
}

/* Table tail row */
.rdt_Pagination > span,
.rdt_Pagination > div,
.rdt_Pagination > div > select {
  font-size: 17px;
  font-family: var(--main-font);
}

.rdt_Pagination > span,
.rdt_Pagination > div,
.rdt_Pagination > div > select {
  font-size: 17px;
  font-family: var(--main-font);
}

.Profile--confirm-window {
  background: rgb(216, 216, 216);
  color: rgb(37, 31, 31);
  font-family: var(--main-font);
}

.Profile-delete-confirmation {
  font-family: var(--main-font);
}

.Profile-delete-confirmation > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Profile-delete-confirmation > div > button {
  margin: 0px 20px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  border-radius: 10px;
  font-family: var(--main-font);
  font-size: 20px;
  color: rgb(245, 245, 245);
  margin-top: 10px;
  width: 65%;
}

.Profile-delete-confirmation > div > button:hover {
  box-shadow: 0 0 11px rgb(37, 31, 31);
}

.Profile-confirm-delete-btn {
  background-color: red;
}
.Profile-refuse-delete-btn {
  background-color: rgb(37, 31, 31);
}

.Profile--logged-out {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.Profile--logged-out > h1 {
  margin: 50px;
  font-family: var(--main-font);
}

.custom-no-data-background > div > div > div {
  background-color: rgb(216, 216, 216);
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.Profile--main-mobile {
  margin: 30px 0px 500px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--main-font);
}

.Profile--tournament-table-mobile {
  width: 98%;
}

.Profile--user-data-mobile {
  display: flex;
  flex-direction: column;
  width: 98%;
}

.Profile--user-data-mobile > div,
.Profile--user-data-mobile > div > a {
  font-size: 30px;
}

/* Table all components */
.Profile--tournament-table-mobile .rdt_TableHeadRow,
.Profile--tournament-table-mobile .rdt_TableRow,
.Profile--tournament-table-mobile .rdt_Pagination {
  background-color: rgb(216, 216, 216);
  font-size: 28px;
  font-family: var(--main-font);
}

/* Table head row */
.Profile--tournament-table-mobile .rdt_TableHeadRow {
  background-color: rgb(37, 31, 31);
  color: rgb(245, 245, 245);
  font-size: 28px;
  height: 70px;
}

.Profile--tournament-table-mobile .rdt_TableCol_Sortable {
  display: flex;
  justify-content: center;
}

/* Table row */
.Profile--tournament-table-mobile .rdt_TableRow {
  height: 90px;
}

/* Table cell */
.Profile--tournament-table-mobile .rdt_TableCell {
  display: flex;
  /* justify-content: center; */
  /* color: red; */
}

/* Pagination bar */
.Profile--tournament-table-mobile .svYMe {
  margin-right: 20px;
  width: 50px;
}

.Profile--tournament-table-mobile .svYMe > select {
  font-size: 25px;
}

.Profile--tournament-table-mobile .svYMe > svg {
  transform: scale(2);
}

.Profile--tournament-table-mobile .rdt_Pagination {
  display: flex;
}

.Profile--tournament-table-mobile .rdt_Pagination > span {
  font-size: 26px;
}
.Profile--tournament-table-mobile .rdt_Pagination > div > button {
  width: 100px;
}
.Profile--tournament-table-mobile .rdt_Pagination > div > button > svg {
  transform: scale(2.5);
}

.Profile--buttons-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Profile--buttons-mobile > a,
.Profile--buttons-mobile > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 30px;
  background-color: rgb(37, 31, 31);
  border-radius: 30px;
  font-family: var(--main-font);
  font-size: 35px;
  color: rgb(245, 245, 245);
  margin: 10px;
}
