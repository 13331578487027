.ElevatorText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: var(--main-font);
  font-size: 18px;
}

.ElevatorText--main-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid rgb(37, 31, 31);
  border-bottom: 2px solid rgb(37, 31, 31);
  width: 70%;
  margin: 40px 0px 0px 0px;
  padding: 20px;
}

.ElevatorText--main-text > div {
  display: flex;
  flex-direction: row;
}

.ElevatorText--italic {
  font-style: italic;
  margin: 20px 10px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
}

.ElevatorText--highlighted {
  background-image: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  -webkit-background-clip: text; /* For compatibility with some browsers */
  background-clip: text;
  color: transparent; /* Hide the actual text color */
  font-family: "Caesar dressing", sans-serif;
  margin: 17px 10px;
  font-size: 22px;
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.ElevatorText--main-text-mobile {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* border-top: 2px solid rgb(37, 31, 31); */
  border-bottom: 2px solid rgb(37, 31, 31);
  width: 80%;
  margin: 40px 0px 0px 0px;
  padding: 20px;
  font-size: 30px;
}
