.hamburger-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Change to column to make the menu appear below the button */
  position: relative; /* Add this for positioning the menu */
}

.hamburger-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
}

.line {
  background: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  /* background-color: #333; */
  height: 5px;
  width: 35px;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 20px;
}

.open.line {
  width: 42px;
}

.menu {
  position: absolute; /* Position the menu absolutely */
  top: 65px; /* Adjust this as needed to position the menu relative to the button */
  background: #fff;
  display: flex; /* Display as flex */
  flex-direction: column; /* Stack menu items vertically */
  border-radius: 5px;
  max-height: 0; /* Start with a max-height of 0 */
  overflow: hidden; /* Hide overflow content */
  transition: max-height 0.3s ease; /* Add max-height transition */
}

.open.menu {
  max-height: 200px; /* Adjust the max-height to show menu items */
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 11px rgba(117, 116, 116, 0.7);
}

.menu > a,
.menu > p {
  display: flex;
  margin: 10px 5px;
  text-decoration: none;
  padding: 1px 15px;
  font-size: 17px;
}

.menu > a:hover,
.menu > p:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.7);
  border-radius: 15px;
}

.Hamburger-menu--logout {
  color: red;
  cursor: pointer;
}

/* ======================================================*/
/* --------------------- Mobile css ---------------------*/
/* ======================================================*/

.line-mobile {
  background: linear-gradient(
    90deg,
    rgba(255, 164, 46, 1) 0%,
    rgba(247, 28, 61, 1) 100%
  );
  /* background-color: #333; */
  height: 7px;
  width: 50px;
  margin: 9px 0;
  transition: 0.4s;
  border-radius: 20px;
}

.open-mobile.line-mobile {
  width: 60px;
}

.menu-mobile {
  position: absolute; /* Position the menu absolutely */
  top: 85px; /* Adjust this as needed to position the menu relative to the button */
  right: 0px;
  background: #fff;
  display: flex; /* Display as flex */
  flex-direction: column; /* Stack menu items vertically */
  border-radius: 5px;
  max-height: 0; /* Start with a max-height of 0 */
  overflow: hidden; /* Hide overflow content */
  transition: max-height 0.3s ease; /* Add max-height transition */
}

.open-mobile.menu-mobile {
  max-height: 300px; /* Adjust the max-height to show menu items */
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 11px rgba(117, 116, 116, 0.7);
}

.menu-mobile > a,
.menu-mobile > p {
  display: flex;
  text-decoration: none;
  padding: 1px 15px;
  margin: 25px 5px;
  font-size: 30px;
}

.Hamburger-menu--logout {
  color: red;
  cursor: pointer;
}
